import payload_plugin_qLmFnukI99 from "/codebuild/output/src4012298880/src/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src4012298880/src/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src4012298880/src/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_I4dbrL2rYz from "/codebuild/output/src4012298880/src/repo/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_ghbUAjaD3n from "/codebuild/output/src4012298880/src/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_4F1kZh3YAB from "/codebuild/output/src4012298880/src/repo/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_PSbK9A48ZQ from "/codebuild/output/src4012298880/src/repo/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import google_recaptcha_43SsEJM2qi from "/codebuild/output/src4012298880/src/repo/modules/auth/plugins/google-recaptcha.ts";
import sentry_3AyO8nEfhE from "/codebuild/output/src4012298880/src/repo/plugins/sentry.ts";
import dayjs_pGiXRjcsJO from "/codebuild/output/src4012298880/src/repo/plugins/dayjs.ts";
import api_GFfDXud5Cr from "/codebuild/output/src4012298880/src/repo/plugins/api.ts";
import maska_UHaKf2z1iQ from "/codebuild/output/src4012298880/src/repo/plugins/maska.ts";
import vuetify_7h9QAQEssH from "/codebuild/output/src4012298880/src/repo/plugins/vuetify.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_I4dbrL2rYz,
  plugin_ghbUAjaD3n,
  plugin_client_4F1kZh3YAB,
  plugin_PSbK9A48ZQ,
  google_recaptcha_43SsEJM2qi,
  sentry_3AyO8nEfhE,
  dayjs_pGiXRjcsJO,
  api_GFfDXud5Cr,
  maska_UHaKf2z1iQ,
  vuetify_7h9QAQEssH
]