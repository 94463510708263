import { default as aboutCuHiEqjO1IMeta } from "/codebuild/output/src4012298880/src/repo/pages/account/about.vue?macro=true";
import { default as faqCukyl63yc4Meta } from "/codebuild/output/src4012298880/src/repo/pages/account/faq.vue?macro=true";
import { default as help_45centre6pBOcNHDLVMeta } from "/codebuild/output/src4012298880/src/repo/pages/account/help-centre.vue?macro=true";
import { default as indexDGxukrZgJJMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/index.vue?macro=true";
import { default as my_45orderscdzLxHvdduMeta } from "/codebuild/output/src4012298880/src/repo/pages/account/my-orders.vue?macro=true";
import { default as personal_45detailsaRIN1xrhsXMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/personal-details.vue?macro=true";
import { default as delete_45accountduJCu0TyfSMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/settings/delete-account.vue?macro=true";
import { default as forget_45passwordBlATdMaTwoMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/settings/forget-password.vue?macro=true";
import { default as indexKqZ2tFDiMkMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/settings/index.vue?macro=true";
import { default as update_45email_45addressekiLOf4KGmMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/settings/update-email-address.vue?macro=true";
import { default as update_45passwordquH5fs3r4CMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/settings/update-password.vue?macro=true";
import { default as contact_45usoyaW8RR6T0Meta } from "/codebuild/output/src4012298880/src/repo/pages/contact-us.vue?macro=true";
import { default as frankieone_45verificationiBKQdUNTLOMeta } from "/codebuild/output/src4012298880/src/repo/pages/frankieone-verification.vue?macro=true";
import { default as index2WLroBdNCdMeta } from "/codebuild/output/src4012298880/src/repo/pages/index.vue?macro=true";
import { default as logincwFycvxRg6Meta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/login.vue?macro=true";
import { default as logoutmcM8VT7CdZMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/logout.vue?macro=true";
import { default as notificationscVEX6GPJ1aMeta } from "/codebuild/output/src4012298880/src/repo/pages/notifications.vue?macro=true";
import { default as _91clientSlug_93J4WK0CMTMiMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/onboarding/[clientSlug].vue?macro=true";
import { default as disclaimerXbFdemA1nMMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/onboarding/disclaimer.vue?macro=true";
import { default as id_45selectorxHE5oxgG6MMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/onboarding/id-selector.vue?macro=true";
import { default as indexRNYaltsdTvMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/onboarding/index.vue?macro=true";
import { default as welcomepnc30h9gO3Meta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/onboarding/welcome.vue?macro=true";
import { default as privacy_45policys6U4olCMXwMeta } from "/codebuild/output/src4012298880/src/repo/pages/privacy-policy.vue?macro=true";
import { default as indexnJXfv9NBrbMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/index.vue?macro=true";
import { default as educationxRpguV2pXJMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/education.vue?macro=true";
import { default as identity_45documentsS1hWNzSm7GMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/identity-documents.vue?macro=true";
import { default as indexKwkUPxL3uAMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/index.vue?macro=true";
import { default as personal_45detailsashPNExU6PMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/personal-details.vue?macro=true";
import { default as _91document_9359XvppMtvYMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/required-documents/[document].vue?macro=true";
import { default as index5SvvzSWUhKMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/required-documents/index.vue?macro=true";
import { default as training_45and_45qualificationsVZXbZDBzv1Meta } from "/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/training-and-qualifications.vue?macro=true";
import { default as work_45experiencet3zR6WYsgrMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/work-experience.vue?macro=true";
import { default as your_45profileJpr5dOwAOzMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/your-profile.vue?macro=true";
import { default as availabilities6K3u1aktpsMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/services-offered/availabilities.vue?macro=true";
import { default as indexPCPUK9pzSxMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/services-offered/index.vue?macro=true";
import { default as indicative_45ratesznQqL2X5o0Meta } from "/codebuild/output/src4012298880/src/repo/pages/profile/services-offered/indicative-rates.vue?macro=true";
import { default as service_45offerings0A8MD9HVmwMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/services-offered/service-offerings.vue?macro=true";
import { default as support_45seeker_45preferencestMeuKG8txlMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/services-offered/support-seeker-preferences.vue?macro=true";
import { default as indexGZCBzMldO7Meta } from "/codebuild/output/src4012298880/src/repo/pages/profile/support-requirements/index.vue?macro=true";
import { default as qualifications_45neededgmQec27tgWMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/support-requirements/qualifications-needed.vue?macro=true";
import { default as required_45work_45hoursQ3KK6uwaGyMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/support-requirements/required-work-hours.vue?macro=true";
import { default as support_45preferencesOrmVVNaKKBMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/support-requirements/support-preferences.vue?macro=true";
import { default as support_45requirementsKUefnfJDwlMeta } from "/codebuild/output/src4012298880/src/repo/pages/profile/support-requirements/support-requirements.vue?macro=true";
import { default as redirectskeWAYVE6mMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/redirect.vue?macro=true";
import { default as registerKjB6aIwodtMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/register.vue?macro=true";
import { default as requestsxJNfiSeLSeMeta } from "/codebuild/output/src4012298880/src/repo/pages/requests.vue?macro=true";
import { default as reset_45password9JFdL0KTwOMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/reset-password.vue?macro=true";
import { default as indexiXnnDHMROKMeta } from "/codebuild/output/src4012298880/src/repo/pages/search/index.vue?macro=true";
import { default as _91id_93QQ7Jzr51adMeta } from "/codebuild/output/src4012298880/src/repo/pages/search/support-workers/[id].vue?macro=true";
import { default as showcasekMy9bioNuVMeta } from "/codebuild/output/src4012298880/src/repo/pages/showcase.vue?macro=true";
import { default as signinyilg4yjdurMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/signin.vue?macro=true";
import { default as subscriptionmAQzrv4MDVMeta } from "/codebuild/output/src4012298880/src/repo/modules/subscription/pages/subscription.vue?macro=true";
import { default as terms_45of_45use5IaHsWk1nqMeta } from "/codebuild/output/src4012298880/src/repo/pages/terms-of-use.vue?macro=true";
import { default as verifypj32FIVbTTMeta } from "/codebuild/output/src4012298880/src/repo/modules/auth/pages/verify.vue?macro=true";
export default [
  {
    name: "account-about",
    path: "/account/about",
    meta: aboutCuHiEqjO1IMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/account/about.vue").then(m => m.default || m)
  },
  {
    name: "account-faq",
    path: "/account/faq",
    meta: faqCukyl63yc4Meta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/account/faq.vue").then(m => m.default || m)
  },
  {
    name: "account-help-centre",
    path: "/account/help-centre",
    meta: help_45centre6pBOcNHDLVMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/account/help-centre.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexDGxukrZgJJMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-my-orders",
    path: "/account/my-orders",
    meta: my_45orderscdzLxHvdduMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/account/my-orders.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-details",
    path: "/account/personal-details",
    meta: personal_45detailsaRIN1xrhsXMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/personal-details.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-delete-account",
    path: "/account/settings/delete-account",
    meta: delete_45accountduJCu0TyfSMeta || {},
    alias: ["/my-profile/account-settings/delete-account"],
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/settings/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-forget-password",
    path: "/account/settings/forget-password",
    meta: forget_45passwordBlATdMaTwoMeta || {},
    alias: ["/my-profile/account-settings/forget-password"],
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/settings/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: indexKqZ2tFDiMkMeta || {},
    alias: ["/my-profile/account-settings"],
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-update-email-address",
    path: "/account/settings/update-email-address",
    meta: update_45email_45addressekiLOf4KGmMeta || {},
    alias: ["/my-profile/account-settings/update-email-address"],
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/settings/update-email-address.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-update-password",
    path: "/account/settings/update-password",
    meta: update_45passwordquH5fs3r4CMeta || {},
    alias: ["/my-profile/account-settings/update-password"],
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/account/settings/update-password.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    meta: contact_45usoyaW8RR6T0Meta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "frankieone-verification",
    path: "/frankieone-verification",
    meta: frankieone_45verificationiBKQdUNTLOMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/frankieone-verification.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index2WLroBdNCdMeta || {},
    alias: ["/home"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logincwFycvxRg6Meta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationscVEX6GPJ1aMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-clientSlug",
    path: "/onboarding/:clientSlug()",
    meta: _91clientSlug_93J4WK0CMTMiMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/onboarding/[clientSlug].vue").then(m => m.default || m)
  },
  {
    name: "onboarding-disclaimer",
    path: "/onboarding/disclaimer",
    meta: disclaimerXbFdemA1nMMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/onboarding/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-id-selector",
    path: "/onboarding/id-selector",
    meta: id_45selectorxHE5oxgG6MMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/onboarding/id-selector.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexRNYaltsdTvMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-welcome",
    path: "/onboarding/welcome",
    meta: welcomepnc30h9gO3Meta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/onboarding/welcome.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policys6U4olCMXwMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexnJXfv9NBrbMeta || {},
    alias: ["/my-profile"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-education",
    path: "/profile/profile-information/education",
    meta: educationxRpguV2pXJMeta || {},
    alias: ["/my-profile/profile-info/education"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/education.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-identity-documents",
    path: "/profile/profile-information/identity-documents",
    meta: identity_45documentsS1hWNzSm7GMeta || {},
    alias: ["/my-profile/profile-info/identity-documents"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/identity-documents.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information",
    path: "/profile/profile-information",
    meta: indexKwkUPxL3uAMeta || {},
    alias: ["/my-profile/profile-info"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-personal-details",
    path: "/profile/profile-information/personal-details",
    meta: personal_45detailsashPNExU6PMeta || {},
    alias: ["/my-profile/profile-info/personal-details"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/personal-details.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-required-documents-document",
    path: "/profile/profile-information/required-documents/:document()",
    meta: _91document_9359XvppMtvYMeta || {},
    alias: ["/my-profile/profile-info/required-documents/:document()"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/required-documents/[document].vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-required-documents",
    path: "/profile/profile-information/required-documents",
    meta: index5SvvzSWUhKMeta || {},
    alias: ["/my-profile/profile-info/required-documents"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/required-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-training-and-qualifications",
    path: "/profile/profile-information/training-and-qualifications",
    meta: training_45and_45qualificationsVZXbZDBzv1Meta || {},
    alias: ["/my-profile/profile-info/training-and-qualifications"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/training-and-qualifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-work-experience",
    path: "/profile/profile-information/work-experience",
    meta: work_45experiencet3zR6WYsgrMeta || {},
    alias: ["/my-profile/profile-info/work-experience"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/work-experience.vue").then(m => m.default || m)
  },
  {
    name: "profile-profile-information-your-profile",
    path: "/profile/profile-information/your-profile",
    meta: your_45profileJpr5dOwAOzMeta || {},
    alias: ["/my-profile/profile-info/your-profile"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/profile-information/your-profile.vue").then(m => m.default || m)
  },
  {
    name: "profile-services-offered-availabilities",
    path: "/profile/services-offered/availabilities",
    meta: availabilities6K3u1aktpsMeta || {},
    alias: ["/my-profile/services/availabilities"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/services-offered/availabilities.vue").then(m => m.default || m)
  },
  {
    name: "profile-services-offered",
    path: "/profile/services-offered",
    meta: indexPCPUK9pzSxMeta || {},
    alias: ["/my-profile/services"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/services-offered/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-services-offered-indicative-rates",
    path: "/profile/services-offered/indicative-rates",
    meta: indicative_45ratesznQqL2X5o0Meta || {},
    alias: ["/my-profile/services/rates"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/services-offered/indicative-rates.vue").then(m => m.default || m)
  },
  {
    name: "profile-services-offered-service-offerings",
    path: "/profile/services-offered/service-offerings",
    meta: service_45offerings0A8MD9HVmwMeta || {},
    alias: ["/my-profile/services/service-offerings"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/services-offered/service-offerings.vue").then(m => m.default || m)
  },
  {
    name: "profile-services-offered-support-seeker-preferences",
    path: "/profile/services-offered/support-seeker-preferences",
    meta: support_45seeker_45preferencestMeuKG8txlMeta || {},
    alias: ["/my-profile/services/preferences"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/services-offered/support-seeker-preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-support-requirements",
    path: "/profile/support-requirements",
    meta: indexGZCBzMldO7Meta || {},
    alias: ["/my-profile/support-reqs"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/support-requirements/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-support-requirements-qualifications-needed",
    path: "/profile/support-requirements/qualifications-needed",
    meta: qualifications_45neededgmQec27tgWMeta || {},
    alias: ["/my-profile/support-reqs/qualifications-needed"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/support-requirements/qualifications-needed.vue").then(m => m.default || m)
  },
  {
    name: "profile-support-requirements-required-work-hours",
    path: "/profile/support-requirements/required-work-hours",
    meta: required_45work_45hoursQ3KK6uwaGyMeta || {},
    alias: ["/my-profile/support-reqs/required-work-hours"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/support-requirements/required-work-hours.vue").then(m => m.default || m)
  },
  {
    name: "profile-support-requirements-support-preferences",
    path: "/profile/support-requirements/support-preferences",
    meta: support_45preferencesOrmVVNaKKBMeta || {},
    alias: ["/my-profile/support-reqs/support-preferences"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/support-requirements/support-preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-support-requirements-support-requirements",
    path: "/profile/support-requirements/support-requirements",
    meta: support_45requirementsKUefnfJDwlMeta || {},
    alias: ["/my-profile/support-reqs/support-requirements"],
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/profile/support-requirements/support-requirements.vue").then(m => m.default || m)
  },
  {
    name: "redirect",
    path: "/redirect",
    meta: redirectskeWAYVE6mMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerKjB6aIwodtMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "requests",
    path: "/requests",
    meta: requestsxJNfiSeLSeMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/requests.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password9JFdL0KTwOMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    meta: indexiXnnDHMROKMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search-support-workers-id",
    path: "/search/support-workers/:id()",
    meta: _91id_93QQ7Jzr51adMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/search/support-workers/[id].vue").then(m => m.default || m)
  },
  {
    name: "showcase",
    path: "/showcase",
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/showcase.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinyilg4yjdurMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: subscriptionmAQzrv4MDVMeta || {},
    alias: ["/my-profile/subscribe"],
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/subscription/pages/subscription.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    meta: terms_45of_45use5IaHsWk1nqMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifypj32FIVbTTMeta || {},
    component: () => import("/codebuild/output/src4012298880/src/repo/modules/auth/pages/verify.vue").then(m => m.default || m)
  }
]